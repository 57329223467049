import i18n from '@/i18n.js'
import QuestionCategoryService from '../services/question_category.service';
import router from "@/router";
import Vue from "vue";
export const question_category = {
    namespaced: true,
    state: () => ({
        type: null,
        send_type: true,
    }),
    mutations: {
        SET_TYPE(state, data) {
            state.type = data
        },
        SET_SEND_TYPE(state, data) {
            state.send_type = data
        }
    },
    actions: {
        // set_type_id({ commit, rootState }, value) {
        //     commit('SET_TYPE', value)
        //     rootState.form.style_form.map((v) => {
        //         if (v.value_text == "custome_user") {
        //             v.visible = true
        //             v.value = ""
        //         } else if (v.value_text == "users") {
        //             v.visible = false
        //             v.value = []
        //         }

        //     });
        // },
        custome_user({ commit, state, rootState }, value) {
            // console.log("custome user", state.type)
            // rootState.card.loading = true
            if (value == true) {
                rootState.form.style_form.map((v) => {
                    if (v.value_text == "users") {
                        v.visible = true
                        // v.items = state.type.users
                    }
                    else if (v.value_text == "types") {
                        v.visible = false
                        v.value = []
                    }
                    return v
                });
            } else {
                // commit('SET_SEND_TYPE', true)
                rootState.form.style_form.map((v) => {
                    if (v.value_text == "users") {
                        v.visible = false
                        v.value = []
                    } else if (v.value_text == "types") {
                        v.visible = true
                    }
                    return v
                });
            }
        },
        // select_user({ commit }, value) {
        //     if (value) {
        //         commit('SET_SEND_TYPE', false)
        //     } else
        //         commit('SET_SEND_TYPE', true)

        // },
        // square
        delete_question_category({ rootState }, item) {
            Vue.swal({
                title: i18n.t("Are you sure?"),
                text: i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("Yes, delete it!"),
                cancelButtonText: i18n.t("No, cancel!"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    return QuestionCategoryService.destroy_question_category(item.id).then(
                        (response) => {
                            // console.log(response)
                            rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                            Vue.swal.fire(i18n.t("Deleted"), response.data.message, "success");
                        },
                        (error) => {
                            console.log(error);
                            if (error.response.status != 401) {
                                Vue.swal.fire(
                                    i18n.t("Error"),
                                    i18n.t("There error please try again"),
                                    "error"
                                );
                            }
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(i18n.t("Cancelled"), i18n.t("Cancelled Delete"), "error");
                }
            });
        },

        add_question_category({ state, rootState }, data) {

            if (rootState.form.style_form[2].value == true && rootState.form.style_form[4].value.length == 0) {
                rootState.form.notify = {
                    msg: "الرجاء تحديد مستخدمين",
                    type: "Danger",
                };
                rootState.form.loader = false;
                return false
            } else if (rootState.form.style_form[2].value == false && rootState.form.style_form[3].value.length == 0) {
                rootState.form.notify = {
                    msg: "الرجاء تحديد أنواع مستخدمين",
                    type: "Danger",
                };
                rootState.form.loader = false;
                return false
            }


            return QuestionCategoryService.add_question_category(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "QuestionCategoryPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        update_question_category({ state, rootState }, data) {
            if (rootState.form.style_form[2].value == true && rootState.form.style_form[4].value.length == 0) {
                rootState.form.notify = {
                    msg: "الرجاء تحديد مستخدمين",
                    type: "Danger",
                };
                rootState.form.loader = false;
                return false
            } else if (rootState.form.style_form[2].value == false && rootState.form.style_form[3].value.length == 0) {
                rootState.form.notify = {
                    msg: "الرجاء تحديد أنواع مستخدمين",
                    type: "Danger",
                };
                rootState.form.loader = false;
                return false
            }



            return QuestionCategoryService.edit_question_category(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "QuestionCategoryPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },


        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section
        filterData({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return QuestionCategoryService.get_question_categories(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};
