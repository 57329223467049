
class OrderService {
  get_orders(page, post) {
    return axios.post('general/orders?page=' + page, post)
  }
  detail_specialist_services_by_order(id, data) {
    return axios.post('general/orders/specialist-with-attach/' + id, data)
  }
  detail_order(id, data) {
    return axios.post('general/orders/details/' + id, data)
  }
  get_order(id) {
    return axios.get('general/orders/get-order/' + id)
  }
  get_square_by_camps(data) {
    return axios.post('general/orders/square-by-camp', data)
  }
  approve_order(data) {
    return axios.post('general/orders/approve', data)
  }
  approve_sharer(id, data) {
    return axios.post('general/order_sign/sign/' + id, data)
  }
  disapprove_sharer(id, data) {
    return axios.post('general/order_sign/unsign/' + id, data)
  }
  check_sign_sharer(id) {
    return axios.post('general/order_sign/check-sign/' + id)
  }
  sign_sharer_detail(id, data) {
    return axios.post('general/order_sign/' + id, data)
  }
  rejected_order(data) {
    return axios.post('general/orders/disapprove', data)
  }
  cancel_order(id) {
    return axios.delete('general/orders/destroy/' + id)
  }
  update_order(id, data) {
    return axios.post('general/orders/update/' + id, data)
  }
  re_assign_order(id, data) {
    return axios.post('general/orders/update-receiver/' + id, data)
  }
  add_order(data) {
    return axios.post('general/orders/store', data)
  }
  create_order() {
    return axios.get('general/orders/create')
  }
  edit_order(id) {
    return axios.get('general/orders/edit/' + id)
  }
  change_specialist(id, data) {
    return axios.post('general/orders/add-specialist/' + id, data)
  }
  approve_add_specialist(id, data) {
    return axios.post('general/orders/approve-add-specialist/' + id, data)
  }
  get_specialist_services_by_order(id) {
    return axios.get('general/orders/specialist-order/' + id)
  }
  store_services_by_order(data) {
    return axios.post('general/orders/answer-inputs', data)
  }
  update_services_by_order(data) {
    return axios.post('general/orders/answer-inputs', data)
  }
  delete_services_by_order(id) {
    return axios.delete('general/orders/services/destroy/' + id)
  }
  upload_specialists_attachments(data) {
    return axios.post('general/orders/uploadSpecialistsAttachments', data)
  }
  upload_pledge_attachments(data) {
    return axios.post('general/orders/uploadPledgesAttachments', data)
  }
  dis_approve_attachment(data, type) {
    if (type == 'pledge')
      return axios.post('general/orders/disapprovePladgeAttachment', data)
    else if (type == 'spacality')
      return axios.post('general/orders/disapproveSpecialistsAttachment', data)
    else if (type == 'fire')
      return axios.post('general/orders/disapproveAttachment', data)
  }
  get_order_pledges(id, data) {
    return axios.post('general/orders/getOrderPledges/' + id, data)
  }
  get_order_req_pledges(data) {
    return axios.post('general/orders/required-pledges', data)
  }
  get_fire_attachment(id, data) {
    return axios.post('general/orders/getOrderAttachments/' + id, data)
  }
  get_fire_attachment_upload(id) {
    return axios.get('general/orders/find-safty/' + id)
  }
  rejected_order_by_tsleem(id) {
    return axios.post('general/orders/cancel-by-tsleem/' + id)
  }
  delete_fire_attachment(id) {
    return axios.delete('general/orders/deleteOrderAttachment/' + id)
  }
  get_order_log(id, data) {
    return axios.post('general/order_log/' + id, data)
  }
  get_license_ready(id, data) {
    return axios.post('general/orders/ready-license/' + id, data)
  }
  get_elect_data(id, data) {
    return axios.post('general/orders/get-elect-data/' + id, data)
  }
  renew_license_ready(id) {
    return axios.post('general/orders/extend_license/' + id)
  }
  get_license_add(id, data) {
    return axios.post('general/orders/add-license/' + id, data)
  }
  get_check_qr(id) {
    return axios.get('general/orders/check-qr/' + id)
  }
  change_choosing(data) {
    return axios.post('general/orders/updateContractorsList', data)
  }
  change_contractor(data) {
    return axios.post('general/orders/updateContracter', data)
  }
  change_consulting(data) {
    return axios.post('general/orders/updateConsulting', data)
  }
  change_consulting_contractor(data) {
    return axios.post('general/orders/updateBoth', data)
  }

  update_contractor(data) {
    return axios.post('general/orders/canceled_requests/update_contractor', data)
  }
  update_consulting(data) {
    return axios.post('general/orders/canceled_requests/update_consultant', data)
  }

  approve_dis_contracter(data) {
    return axios.post('general/orders/contracting', data)
  }
  approve_dis_consultor(data) {
    return axios.post('general/orders/consulting', data)
  }
  cancel_consulting_contractor(data) {
    return axios.post('general/orders/canceled_requests/store', data)
  }
  cancel_consulting_contractor(data) {
    return axios.post('general/orders/canceled_requests/store', data)
  }
  change_status_cancel_request(data) {
    return axios.post('general/orders/canceled_requests/change_request_status', data)
  }
  // for design office (prepare order)
  count_pledges(id) {
    return axios.get('general/orders/getRequiredPledgesCount/' + id)
  }
  // for design office (prepare order)
  count_services(id) {
    return axios.get('general/services/specialities-services/' + id)
  }
  // for tasleem
  count_attachments(id) {
    return axios.get('general/orders/getAttachmentCount/' + id)
  }
  get_report(id, data) {
    return axios.post('general/orders/achive_reports/' + id, data)
  }
  uploaded_report(data) {
    return axios.post('general/orders/achive_reports/store', data)
  }
  change_report_status(data) {
    return axios.post('general/orders/achive_reports/change_report_status', data)
  }
  get_available_report(data) {
    return axios.post('general/orders/achive_reports/get_available_report', data)
  }
  uploadGis(id, data) {
    return axios.post('general/orders/add-gis-img/' + id, data)
  }
  update_electricity_delivery(id, data) {
    return axios.post('general/electricity_delivery/update/' + id, data)
  }
  activate_electricity_delivery(id, data) {
    return axios.post('general/electricity_delivery/activate/' + id, data)
  }
  enter_electrical_meter(data) {
    return axios.post('general/electrical_meter_details/update-camp-details', data)
  }
  update_electrical_meter(id, data) {
    return axios.post('general/electricity_delivery/update_details/' + id, data)
  }
}
export default new OrderService();