import ReadinessService from "../services/simple_readiness.service";
import router from "@/router";
export const readiness_license = {
  namespaced: true,
  state: () => ({
    formData: [],
    validityPeriod: 0,
    form_fields: [],
    form_id: null,
    camp_id: null,
    order_id: null,
    answer_default: true,
    is_committed: false,
    has_answers: true,
    order: null
  }),
  mutations: {
    SET_FORM(state, form) {
      state.formData = form;
    },
    SET_ORDER(state, data) {
      state.order = data;
    },
    SET_FORM_FIELDS(state, formField) {
      let field = state.form_fields.find((el) => el.id == formField.id);
      if (field != undefined) {
        field.value = formField.value;
      }
      else {
        state.form_fields.push(formField);
      }
    },
    SET_ANSWER_DEFAULT(state, changed) {
      state.answer_default = changed;
    },
    SET_VALIDITY_PERIOD(state, value) {
      state.validityPeriod = value;
    },
    SET_COMMITTED(state, committed) {
      state.is_committed = committed;
    },
    SET_CAMP(state, camp) {
      state.camp_id = camp;
    },
    SET_FORM_ID(state, id) {
      state.form_id = id;
    },
  },
  getters: {
    getHasAnswers(state) {
      return state.has_answers;
    }
  },
  actions: {
    add_pladge({ rootState }, data) {
      return ReadinessService.add_pladge(rootState.id, data).then(
        (response) => {
          console.log(response);
          rootState.form.loader = false;
          rootState.form.notify = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          console.log(error);
          rootState.form.loader = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            for (
              let i = 0;
              i < rootState.form.style_form.length;
              i++
            ) {
              const element = rootState.form.style_form[i];
              element.error = errors[element.value_text];
            }
          } else if (error.response.status != 401) {
            rootState.form.notify = {
              msg: i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );

    },
    submit_order({ state, dispatch, rootState }) {
      if (state.camp_id == null) {
        rootState.form.notify = {
          msg: "الرجاء تحديد المخيم",
          type: "Danger"
        };
        return false
      }

      if (state.is_committed == null) {
        rootState.form.notify = {
          msg: "الرجاء الموافقة على التعهد ",
          type: "Danger"
        };
        return false
      }
      let postData = {
        assign_camp_id: state.camp_id,
        is_committed: state.is_committed,
      }


      ReadinessService.post_readiness_order(postData).then((res) => {
        console.log("success:", res);
        state.order_id = res.data.data.id;
        dispatch("submit_answers");
        return Promise.resolve(res);
      }, (error) => {
        console.log("failed:", error.response.message);
        rootState.form.notify = {
          msg: error.response.data.message,
          type: "Danger"
        };
        return Promise.reject(error);
      })

    },
    submit_answers({ rootState, state }) {
      let postData = state.formData;
      if (state.order_id == null) {
        rootState.form.notify = {
          msg: "failure! no order id submitted",
          type: "Danger"
        };
        return;
      }
      postData.append("order_id", state.order_id);
      if (state.form_id == null) {
        rootState.form.notify = {
          msg: "failure! no form id registered",
          type: "Danger"
        };
        return;
      }
      postData.append("form_id", state.form_id);

      ReadinessService.post_form_answers(postData).then((res) => {
        console.log("success:", res);
        rootState.form.notify = {
          msg: res.data.message,
          type: "Success"
        };
        setTimeout(function () {
          router.push(`/readiness-license`);
        }, 1000);
        return Promise.resolve(res);
      },
        (error) => {
          console.error("failed:", error);
          rootState.form.notify = {
            msg: error.response.data.message,
            type: "Danger"
          };
          return Promise.reject(error);
        });
    },
    update_answers({ state, rootState }) {
      let postData = state.formData;

      postData.append("order_id", router.currentRoute.params.id);
      if (state.order_id == null) {
        rootState.form.notify = {
          msg: "Unallowed action!",
          type: "Danger"
        };
        return;
      }
      if (state.form_id == null) {
        rootState.form.notify = {
          msg: "failure! no form id registered",
          type: "Danger"
        };
        return;
      }
      postData.append("form_id", state.form_id);

      ReadinessService.update_form_answers(postData).then((res) => {
        console.log("success:", res);
        rootState.form.notify = {
          msg: res.data.message,
          type: "Success"
        };
        setTimeout(function () {
          router.push(`/readiness-license`);
        }, 1000);
        return Promise.resolve(res);
      },
        (error) => {
          console.error("failed:", error);
          rootState.form.notify = {
            msg: error.data.message,
            type: "Danger"
          };
          return Promise.reject(error);
        });
    },
    approve_order({ state, rootState }) {
      let postData = state.formData;

      postData.append("order_id", router.currentRoute.params.id);
      if (state.order_id == null) {
        rootState.form.notify = {
          msg: "Unallowed action!",
          type: "Danger"
        };
        return;
      }
      if (state.form_id == null) {
        rootState.form.notify = {
          msg: "failure! no form id registered",
          type: "Danger"
        };
        return;
      }
      postData.append("form_id", state.form_id);

      if (state.validityPeriod == 0) {
        rootState.form.notify = {
          msg: "failure! no validity period selected",
          type: "Danger"
        };
        return;
      }
      postData.append("validity_period", state.validityPeriod);

      ReadinessService.approve_order(postData).then((res) => {
        console.log("success:", res);
        rootState.form.notify = {
          msg: res.data.message,
          type: "Success"
        };
        setTimeout(function () {
          router.push(`/readiness-license`);
        }, 1000);
        return Promise.resolve(res);
      },
        (error) => {
          console.error("failed:", error);
          rootState.form.notify = {
            msg: error.data.message,
            type: "Danger"
          };
          return Promise.reject(error);
        });
    },
    disapprove_order({ state, rootState }, data) {
      data.append("id", router.currentRoute.params.id);
      if (state.order_id == null) {
        rootState.form.notify = {
          msg: "Unallowed action!",
          type: "Danger"
        };
        return;
      }
      if (state.form_id == null) {
        rootState.form.notify = {
          msg: "failure! no form id registered",
          type: "Danger"
        };
        return;
      }
      data.append("form_id", state.form_id);

      if (data.get('rejection_reason') == null | "") {
        let message = "failure! no rejection reason given"
        console.error(message);
        rootState.form.notify = {
          msg: message,
          type: "Danger"
        };
        return;
      }

      ReadinessService.disapprove_order(data).then((res) => {
        console.log("success:", res);
        rootState.form.notify = {
          msg: res.data.message,
          type: "Success"
        };
        setTimeout(function () {
          router.push(`/readiness-license`);
        }, 1000);
        return Promise.resolve(res);
      },
        (error) => {
          console.error("failed:", error);
          rootState.form.notify = {
            msg: error.data.message,
            type: "Danger"
          };
          return Promise.reject(error);
        });
    },
    reject_order({ state, rootState }, data) {
      data.append("id", router.currentRoute.params.id);
      if (state.order_id == null) {
        console.error("Unallowed action!");
        return;
      }
      if (state.form_id == null) {
        console.error("failure! no form id registered");
        return;
      }
      data.append("form_id", state.form_id);

      if (data.get('rejection_reason') == null | "") {
        let message = "failure! no rejection reason given"
        console.error(message);
        rootState.form.notify = {
          msg: message,
          type: "Danger"
        };
        return;
      }
      ReadinessService.reject_order(data).then((res) => {
        console.log("success:", res);
        rootState.form.notify = {
          msg: res.data.message,
          type: "success"
        };
        setTimeout(function () {
          router.push(`/readiness-license`);
        }, 1000);
        return Promise.resolve(res);
      },
        (error) => {
          console.error("failed:", error);
          rootState.form.notify = {
            msg: error.data.message,
            type: "error"
          };
          return Promise.reject(error);
        });

    },
    cancel_order({ rootState }, order) {
      let formData = new FormData();
      formData.append("id", order.id);
      formData.append("status", "cancel");

      return ReadinessService.cancel_order(formData).then((res) => {
        // console.log("success:", res);
        rootState.table.items.map(v => {
          if (v.id == order.id) {
            return v.status = "cancelled"
          }
        });
        return Promise.resolve(res);
      },
        (error) => {
          // console.error("failed:", error);
          return Promise.reject(error);
        });

    },
    get_order_details({ state }, id) {
      return ReadinessService.get_readiness_order_details(id).then((res) => {
        console.log("success. res:", res.data);
        let order = res.data.data;
        state.order = order
        state.order_id = order.id;
        if (order.has_answers_in_version) {
          state.has_answers = true;
          state.form_id = order.form_id;
          state.form_data = order.answers;
        } else {
          state.has_answers = "false";
        }

        return Promise.resolve(res);
      }, (error) => {
        console.error("failed. error:", error.data);

        return Promise.reject(error);
      });
    },

    upload_kroky({ commit, rootState }, data) {
      return ReadinessService.upload_kroky(data).then(
        (response) => {
          // console.log(response)
          rootState.table.items.map(v => {
            if (v.id == data.get('order_id')) {
              v.full_path = response.data.data.full_path
            }

          });
          rootState.form.loader = false
          rootState.form.notify = {
            msg: response.data.message,
            type: "Success",
          };
          commit('form/SET_DIALOG', false, { root: true })
        },
        (error) => {
          console.log(error);
          rootState.form.loader = false
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            for (
              let i = 0;
              i < rootState.form.style_form.length;
              i++
            ) {
              const element = rootState.form.style_form[i];
              element.error = errors[element.value_text];
            }
          } else if (error.response.status != 401) {
            rootState.form.notify = {
              msg: i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },

    // pagination section
    handlePageChange({ rootState, dispatch }, page) {
      rootState.table.paginate.page = page
      dispatch("getData", {})
    },

    changeItemPage({ rootState, dispatch }, pre_page) {
      rootState.table.paginate.itemsPerPage = parseInt(pre_page);
      rootState.table.paginate.page = 1;
      dispatch("getData", { pre_page, type: "pre_page" })
    },

    // filter section 
    filterData({ rootState, dispatch }) {
      console.log('filter')
      rootState.table.paginate.page = 1;
      dispatch("getData", {})
    },
    resetFilter({ rootState, dispatch }) {
      rootState.table.paginate.page = 1;
      dispatch("getData", { reset: true })
    },

    getData({ rootState }, data) {
      // console.log(data)
      rootState.table.loading = true;
      const formData = new FormData();
      if (!data.hasOwnProperty('reset')) {

        rootState.form.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
        console.log('reset1')
      }
      if (data.hasOwnProperty('type') && data.type == "pre_page") {
        formData.append(
          "paginate",
          data.pre_page
        );
      } else {
        if (rootState.table.paginate.itemsPerPage != '')
          formData.append(
            "paginate",
            rootState.table.paginate.itemsPerPage
          );
      }
      return ReadinessService.get_readiness_order(rootState.table.paginate.page, formData).then(
        (response) => {
          console.log(response.data);
          rootState.table.items = response.data.data.data
          rootState.table.paginate = {
            total: response.data.data.total,
            itemsPerPage: parseInt(response.data.data.per_page),
            row_pre_page: true,
            page: rootState.table.paginate.page,
          }
          if (data.hasOwnProperty('reset') && data.reset) {
            // console.log('reset2')
            rootState.form.filters = response.data.filters;
          }
          rootState.table.loading = false;
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error);
          rootState.table.loading = false;
          if (error.response.status != 401) {

            rootState.form.notify = {
              msg: i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
          return Promise.reject(error);
        }
      )
    },


  }
}
