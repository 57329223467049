import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
const New = () => import("@/modules/readiness_license/views/New.vue");
const Show = () => import("@/modules/readiness_license/views/Show.vue");
const Edit = () => import("@/modules/readiness_license/views/Edit.vue");
const Process = () => import("@/modules/readiness_license/views/Process.vue");
const Pladge = () => import("@/modules/readiness_license/views/Pladge.vue");
export const ReadinessLicense = {
  path: "/",
  component: DashboardLayout,
  name: "ReadinessLicense",
  children: [
    {
      path: "/readiness-license/create",
      name: "NewReadinessLicense",
      component: New,
      meta: {
        groupName: "ReadinessLicense",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture
        ],
        permissions: "readiness-license-create"
      }
    },
    {
      path: "/readiness-license",
      name: "ShowReadinessLicense",
      component: Show,
      meta: {
        groupName: "ReadinessLicense",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture
        ],
        permissions: "readiness-license-index"
      }
    },
    {
      path: "/readiness-license/edit/:id",
      name: "EditReadinessLicense",
      component: Edit,
      meta: {
        groupName: "ReadinessLicense",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture
        ],
        permissions: "readiness-license-update"
      },
    },
    {
      path: "/readiness-license/details/:id",
      name: "DetailsReadinessLicense",
      component: Process,
      meta: {
        groupName: "ReadinessLicense",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture
        ],
        permissions: "readiness-license-details"
      }
    },

    {
      path: "/readiness-license/pladge",
      name: "ReadinessLicensePladge",
      component: Pladge,
      meta: {
        groupName: "ReadinessLicense",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture
        ],
        permissions: "readiness-license-index"
      }
    },


  ]
}
