import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Archive",
    children: [
        {
            path: "/db-archive",
            name: "DbArchive",
            component: () =>
                import("@/modules/archive/views/Show.vue"),
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "archive-index",
            },
        },
    ]
}