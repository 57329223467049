import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

// assign
const CountriesPage = () => import("@/modules/assign_camp/views/Countries.vue");
const AssignPage = () => import("@/modules/assign_camp/views/Assigns/Show.vue");
const NewAssign = () => import("@/modules/assign_camp/views/Assigns/New.vue");
const EditAssign = () => import("@/modules/assign_camp/views/Assigns/Edit.vue");
const ReAssign = () => import("@/modules/assign_camp/views/Assigns/ReAssign.vue");

// camps
const CampPage = () => import("@/modules/assign_camp/views/Camps/Show.vue");
const NewCamp = () => import("@/modules/assign_camp/views/Camps/New.vue");
const EditCamp = () => import("@/modules/assign_camp/views/Camps/Edit.vue");
const CampTafweejPage = () => import("@/modules/assign_camp/views/Camps/Tafweej.vue");

// square
const SquarePage = () => import("@/modules/assign_camp/views/Squares/Show.vue");
const NewSquare = () => import("@/modules/assign_camp/views/Squares/New.vue");
const EditSquare = () => import("@/modules/assign_camp/views/Squares/Edit.vue");

// appointments
const AppointmentPage = () => import("@/modules/assign_camp/views/Appointments/Show.vue");
const NewAppointment = () => import("@/modules/assign_camp/views/Appointments/New.vue");


export const SquaresPage = {
    path: "/",
    component: DashboardLayout,
    name: "Square",
    children: [{
        path: "/squares/create",
        name: "NewSquare",
        component: NewSquare,
        meta: {
            groupName: "Square",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "square-create",
        },
    },
    {
        path: "/squares/update/:id",
        name: "EditSquare",
        component: EditSquare,
        meta: {
            groupName: "Square",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "square-update",
        },
    },
    {
        path: "/squares",
        name: "SquarePage",
        component: SquarePage,
        meta: {
            groupName: "Square",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-square-index",
        },
    },
    ],
};

export const campsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Camp",
    children: [{
        path: "/camps/create",
        name: "NewCamp",
        component: NewCamp,
        meta: {
            groupName: "Camp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camp-create",
        },
    },
    {
        path: "/camps/update/:id",
        name: "EditCamp",
        component: EditCamp,
        meta: {
            groupName: "Camp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "camp-update",
        },
    },
    {
        path: "/camps",
        name: "CampPage",
        component: CampPage,
        meta: {
            groupName: "Camp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-camp-index",
        },
    },
    {
        path: "/camps-tafweej",
        name: "CampTafweejPage",
        component: CampTafweejPage,
        meta: {
            groupName: "Camp",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-camp-index",
        },
    },
    ],
};

export const AssignsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Assign",
    children: [{
        path: "/assign/create",
        name: "NewAssign",
        component: NewAssign,
        meta: {
            groupName: "Assign",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "assign-create",
        },
    },
    {
        path: "/assign/update/:id",
        name: "EditAssign",
        component: EditAssign,
        meta: {
            groupName: "Assign",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "assign-update",
        },
    },
    {
        path: "/assign/re-customization/:id",
        name: "ReAssign",
        component: ReAssign,
        meta: {
            groupName: "Assign",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "assign-re-customization",
        },
    },
    {
        path: "/assign",
        name: "AssignPage",
        component: AssignPage,
        meta: {
            groupName: "Assign",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-assign-index",
        },
    },
    ],
};

export const CountryPage = {
    path: "/",
    component: DashboardLayout,
    name: "Countries",
    children: [
        {
            path: "/countries",
            name: "CountriesPage",
            component: CountriesPage,
            meta: {
                groupName: "Countries",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-countries-index",
            },
        },
    ]
};
export const AppointmentsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Appointment",
    children: [{
        path: "/create-appointments",
        name: "NewAppointment",
        component: NewAppointment,
        meta: {
            groupName: "Appointment",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-appointment-creat-index",
        },
    },
    {
        path: "/appointments",
        name: "AppointmentPage",
        component: AppointmentPage,
        meta: {
            groupName: "Appointment",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-appointment-index",
        },
    },
    {
        path: "/appointment/bulk-signature",
        name: "BulkSignatureAppointment",
        component: () => import("@/modules/assign_camp/views/Appointments/BulkSignature.vue"),
        meta: {
            groupName: "appointment",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "lock-order-bulk-signature",
        },
    },
    {
        path: "/absence-history",
        name: "Absencehistory",
        component: () => import("@/modules/assign_camp/views/Appointments/Absence.vue"),
        meta: {
            groupName: "appointment",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "absence-history",
        },
    },
        // {
        //     path: "/appointment/contract/:id",
        //     name: "AppointmentContractPage",
        //     component: AppointmentContractPage,
        //     meta: {
        //         groupName: "Appointment",
        //         middlewares: [Middleware.auth, Middleware.permission, Middleware.signeture],
        //         permissions: 'appointment-contract-view',
        //     },
        // },
    ],
};
