export const readiness_license = {
    action: "fa fa-box",
    title: "ReadinessLicense",
    permission: "readiness-license",
    items: [
        {
            prefix: "O",
            title: "ShowReadinessLicense",
            link: "/readiness-license",
            permission: "readiness-license-index",
        },
        {
            prefix: "O",
            title: "NewReadinessLicense",
            link: "/readiness-license/create",
            permission: "readiness-license-create",
        },
        {
            prefix: "O",
            title: "ReadinessLicensePladge",
            link: "/readiness-license/pladge",
            permission: "readiness-license-create",
        },
    ]
}