class SimpleReadiness {
  get_readiness_order(page, data) {
    return axios.post("simple-readiness-license/index?page=" + page, data);
  }
  get_readiness_order_details(id) {
    return axios.get("simple-readiness-license/details/" + id);
  }
  get_form_questions() {
    return axios.get("simple-readiness-license/create");
  }
  get_form_answers(id) {
    return axios.get("simple-readiness-license/question-answer/" + id);
  }
  post_form_answers(formData) {
    return axios.post("simple-readiness-license/answer-questions", formData);
  }
  update_form_answers(formData) {
    return axios.post("simple-readiness-license/update-all-answers", formData);
  }
  post_readiness_order(formData) {
    return axios.post("simple-readiness-license/store", formData);
  }
  disapprove_order(formData) {
    return axios.post("simple-readiness-license/disapprove", formData);
  }
  reject_order(formData) {
    return axios.post("simple-readiness-license/reject", formData);
  }
  approve_order(formData) {
    return axios.post("simple-readiness-license/approve", formData);
  }

  upload_kroky(data) {
    return axios.post("simple-readiness-license/upload-kroky", data);
  }
  cancel_order(formData) {
    return axios.post("simple-readiness-license/cancel", formData);
  }

  add_pladge(id, data) {
    return axios.post("cms/web-settings/update/" + id, data);
  }

  get_pladge() {
    return axios.post("cms/web-settings/get-simple-readiness-license-pladge",);
  }
}

export default new SimpleReadiness();
