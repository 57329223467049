import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function auth({
  nextMiddleware,
  router,
  store,
  to,
  from,
  contextNext,
}) {
  if (!store.state.auth.loggedIn) {
    saveRedirectionIntoStorage(to.fullPath);
    return contextNext({
      path: "/login",
    });
  } else {
    if (store.state.auth.user && store.state.auth.user.is_acknowledged == 0 && store.state.auth.user.type_id != 1 && store.state.auth.is_policy) {

      return contextNext({
        path: "/login",
      });
    }
    // console.log(store.state.auth.user.arafat_status)
    // if (
    //   store.state.auth.user &&
    //   (store.state.auth.user.arafat_status == "rejected" ||
    //     store.state.auth.user.arafat_status == "pending")
    // ) {
    //   return contextNext({
    //     path: "/register-forms",
    //   });
    // } else 
    if (
      store.state.auth.user &&
      store.state.auth.user.arafat_status != "active"
    ) {
      return contextNext({
        path: "/login",
      });
    }
    // else if (
    //   store.state.auth.user &&
    //   store.state.auth.user.arafat_status == "active" && (store.state.auth.user.assign_file_approval == "pending" ||
    //     store.state.auth.user.assign_file_approval == "incorrect")
    // ) {
    //   return contextNext({
    //     path: "/complete-register-forms",
    //   });
    // }
    store.dispatch("notifications/getNotifocations");
    return nextMiddleware();
  }
}
